<template>
    <div class="proContent5">
        <Pro5Com1></Pro5Com1>
        <Pro5Com2 @titleChange="titleChange"></Pro5Com2>
        <Pro5Com3 :activeTitle="activeTitle"></Pro5Com3>
    </div>
</template>

<script>
import Pro5Com1 from "./Pro5Com1.vue";
import Pro5Com2 from "./Pro5Com2.vue";
import Pro5Com3 from "./Pro5Com3.vue";
export default {
    name: "ProContent5",
    data() {
        return {
            activeTitle: "",
        };
    },
    components: {
        Pro5Com1,
        Pro5Com2,
        Pro5Com3,
    },
    methods: {
        titleChange(title) {
            this.activeTitle = title;
        },
    },
};
</script>

<style lang="less">
.proContent5 {
    overflow: hidden;
}
</style>
