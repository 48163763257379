<template>
    <div class="pro5Com3">
        <div class="p53Box">
            <!-- 标题 -->
            <div class="p53Title">客户案例</div>
            <!-- 案例列表 -->
            <div class="p53List">
                <ul>
                    <li
                        class="hb5Li wow animate__animated animate__slideInUp"
                        v-for="item in list"
                        :key="item.id"
                        @click.prevent="goDetail(item)"
                    >
                        <img v-lazy="item.little_image" />
                        <div class="hb5Mask" v-if="item.qrcode || item.logo">
                            <img class="hlCode" v-if="item.qrcode" v-lazy="item.qrcode" />
                            <img class="hlCode" v-else v-lazy="item.logo" />
                            <!-- <p class="hb5Text">{{ item.title }}</p> -->
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 更多案例 -->
            <div class="ct2More" @click="$router.replace('/case')">
                <div class="cmTop"></div>
                <p class="cmp">更多案例</p>
                <div class="cmBottom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Pro5Com3",
    inject: ["getWebData"],
    props: ["activeTitle"],
    data() {
        return {
            list: [],
        };
    },
    methods: {
        async getData() {
            const { data } = await axios.get("api/project/group", this.activeTitle);
            this.list = data;
        },
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            // 点击后的回调
            this.$emit("callBack");
        },
    },
    watch: {
        activeTitle(newVal) {
            console.log(newVal);
            this.getData();
        },
    },
};
</script>

<style lang="less">
.pro5Com3 {
    min-width: 1423px;
    // height: 700px;
    margin-top: 20px;
    .p53Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .p53Title {
            width: 200px;
            height: 50px;
            line-height: 50px;
            font-size: 30px;
            font-weight: 900;
        }
        // 案例列表
        .p53List {
            width: 100%;
            height: 650px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .hb5Li {
                    width: 389px;
                    height: 278px;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    position: relative;
                    cursor: pointer;
                    & > img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: 0.5s;
                    }
                    .hb5Mask {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        background: rgba(0, 0, 0, 0.5);
                        opacity: 0;
                        transform: translateY(30px);
                        border-radius: 10px;
                        transition: 0.5s;
                        .hlCode {
                            width: 120px;
                            height: 120px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            object-fit: contain;
                            // border-radius: 5px;
                            // margin: 0 auto;
                            // margin-top: 85px;
                            // display: block;
                            border-radius: 10px;
                        }
                        .hb5Text {
                            width: 100%;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            color: white;
                            margin-top: 20px;
                        }
                    }
                }
                .hb5Li:hover {
                    img {
                        transform: scale(1.1);
                    }
                    .hb5Mask {
                        transform: translateY(0px);
                        opacity: 1;
                    }
                }
            }
        }
    }
    .ct2More {
        width: 160px;
        height: 38px;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        margin: 0 auto 20px;
        .cmp {
            width: 100%;
            height: 30px;
            line-height: 30px;
            padding-top: 5px;
            color: #7781f1;
        }
        .cmTop {
            width: 100%;
            position: relative;
            transition: 0.5s;
        }
        .cmTop::before {
            border-width: 1px 0 0 1px;
            border-color: #7781f1;
            left: 20px;
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            border-style: solid;

            transition-duration: 0.3s;

            transform: translateZ(0);
        }
        .cmTop::after {
            border-width: 1px 1px 0 0;
            border-color: #7781f1;
            right: 20px;
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            border-style: solid;

            transition-duration: 0.3s;

            transform: translateZ(0);
        }
        .cmBottom {
            width: 100%;
            position: relative;
            transition: 0.5s;
        }
        .cmBottom::before {
            border-width: 0 0 1px 1px;
            border-color: #7781f1;
            left: 20px;
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            border-style: solid;

            transition-duration: 0.3s;

            transform: translateZ(0);
        }
        .cmBottom::after {
            border-width: 0 1px 1px 0;
            border-color: #7781f1;
            right: 20px;
            content: "";
            position: absolute;
            width: 7px;
            height: 7px;
            border-style: solid;
            transition-duration: 0.3s;
            transform: translateZ(0);
        }
    }
    .ct2More:hover {
        .cmTop {
            width: 180px;
            transform: translateY(-10px) translateX(-10px);
        }
        .cmBottom {
            width: 180px;
            transform: translateY(10px) translateX(-10px);
        }
    }
}
</style>
