<template>
    <div class="pro5Com1" v-if="getAdvData(30).children">
        <a :href="getAdvData(30).children[0].url" target="_blank" rel="noopener noreferrer">
            <img v-if="getAdvData(30).children[0].image" :src="getAdvData(30).children[0].image" class="p5Bg" />
            <div class="p51Box">
                <!-- 左文 -->
                <div class="p51Left">
                    <p class="p51Small wow animate__animated animate__slideInLeft">归纳400多种表现形式、特殊功能、推广方式</p>
                    <p class="p51Big wow animate__animated animate__slideInRight">总结出300多类升级改造方案</p>
                </div>
                <!-- 右图 -->
                <div class="p51Right">
                    <img
                        :src="getAdvData(30).children[0].children[0].image"
                        class="p51Img wow animate__animated animate__slideInUp"
                    />
                    <img
                        :src="getAdvData(30).children[0].children[1].image"
                        class="p51Img wow animate__animated animate__rotateIn"
                        data-wow-delay="0.5s"
                    />
                    <img
                        :src="getAdvData(30).children[0].children[2].image"
                        class="p51Img wow animate__animated animate__rotateIn"
                        data-wow-delay="0.7s"
                    />
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "Pro5Com1",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro5Com1 {
    min-width: 1423px;
    height: 600px;
    position: relative;
    .p5Bg {
        position: absolute;
        top: 0;
        left: 0;
        // transform: translateX(-50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .p51Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左文
        .p51Left {
            width: 50%;
            height: 100px;
            text-align: center;
            color: white;
            float: left;
            margin-top: 250px;
            .p51Small {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 28px;
            }
            .p51Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 46px;
                font-weight: 900;
            }
        }
        // 右图
        .p51Right {
            width: 50%;
            height: 100%;
            float: right;
            position: relative;
            .p51Img:nth-child(2) {
                position: absolute;
                top: 0;
                left: 30px;
            }
            .p51Img:nth-child(3) {
                position: absolute;
                top: -20px;
                left: 30px;
            }
        }
    }
}
</style>
