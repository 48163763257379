<template>
    <div class="pro5Com2">
        <div class="p52Box">
            <div class="p52Tops">
                <ul>
                    <li
                        class="p52tLi"
                        v-for="el in list"
                        :key="el.id"
                        :class="{ p52LA: activeId === el.id }"
                        @click="selectActiveId(el.id)"
                    >
                        {{ el.title }}
                    </li>
                </ul>
            </div>
            <!-- 顶部导航 -->
            <div class="p52Tabs">
                <ul>
                    <li
                        class="p52Li"
                        v-for="el in children"
                        :key="el.id"
                        :class="{ p52Active: activeItem === el.id }"
                        @mousemove="activeItem = el.id"
                    >
                        {{ el.title }}
                    </li>
                </ul>
            </div>
            <div class="p52Content" v-if="obj">
                <!-- 左文 -->
                <div class="p52Left" :style="!obj.image ? 'flex:1;' : ''">
                    {{ obj.details }}
                </div>
                <!-- 右图 -->
                <div class="p52Right" v-if="obj.image">
                    <img :src="obj.image" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Pro5Com2",
    data() {
        return {
            activeId: "",
            activeItem: "",
            list: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            const { data } = await axios.get("/api/group/show");
            this.list = data;
            this.activeId = data[0].id;
            this.$nextTick(() => {
                this.activeItem = this.children[0].id;
                this.$emit("titleChange", this.list.find(e => e.id == this.activeId).title);
            });
        },
        selectActiveId(id) {
            this.activeId = id;
            this.$emit("titleChange", this.list.find(e => e.id == id).title);
            this.$nextTick(() => {
                this.activeItem = this.children[0].id;
            });
        },
    },
    computed: {
        children() {
            if (!this.list.length) return [];
            return this.list.find(e => e.id == this.activeId).children;
        },
        obj() {
            if (!this.children.length) return {};
            return this.children.find(e => e.id == this.activeItem);
        },
    },
};
</script>

<style lang="less">
.pro5Com2 {
    min-width: 1423px;
    height: 900px;
    margin-top: 20px;
    text-align: left;
    .p52Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        .p52Tops {
            width: 100%;
            height: 200px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                .p52tLi {
                    min-width: 115px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 25px;
                    margin-top: 10px;
                    margin-left: 10px;
                    cursor: pointer;
                }
                .p52tLi:hover {
                    color: white;
                    background-color: #7781f1;
                }
                .p52LA {
                    color: white;
                    background-color: #7781f1;
                }
            }
        }

        // 顶部导航
        .p52Tabs {
            width: 50%;
            height: 70px;
            margin: 0 auto;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                line-height: 70px;
                text-align: center;
                font-size: 18px;
                font-weight: 900;
                display: flex;
                justify-content: space-around;
                .p52Li {
                    width: 30%;
                    height: 70px;
                    font-weight: 900;
                }
                .p52Active {
                    border-bottom: 5px solid #7781f1;
                    box-sizing: border-box;
                    color: #7781f1;
                }
            }
        }
        // 内容
        .p52Content {
            width: 100%;
            height: 550px;
            margin-top: 50px;
            border-radius: 20px;
            display: flex;
            justify-content: space-between;
            // 左文
            .p52Left {
                width: 45%;
                height: 100%;
            }
            // 右图
            .p52Right {
                width: 45%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
